@import './locomotive-scroll.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "NueMontreal";
    src: local("NueMontreal"), url("../fonts/PPNeueMontreal-Thin.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "NueMontreal";
    src: local("NueMontreal"), url("../fonts/PPNeueMontreal-Book.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "NueMontreal";
    src: local("NueMontreal"), url("../fonts/PPNeueMontreal-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "NueMontreal";
    src: local("NueMontreal"), url("../fonts/PPNeueMontreal-Bold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "flexible";
    src: local("flexible"), url("../fonts/Fontspring-DEMO-flexible-variable.ttf") format("truetype");
}

html {
  border-color: '#EEEEEE';
}

body {
    margin: 0;
    font-family: NueMontreal, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: '#EEEEEE';
}

.font-flexible {
    font-family: 'flexible';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}